import { Box, Panel } from '@bigcommerce/big-design';
import WebhookForm from 'components/forms/webhook';
import { useWebhooks } from 'hooks';

const Webhooks = [
    { name: 'Custom Cart Pricing - Updates', scope: 'store/cart/lineItem/updated', controller: 'CustomPricingWebhook' },
    { name: 'Custom Cart Pricing - Deletes', scope: 'store/cart/lineItem/deleted', controller: 'CustomPricingWebhook' },
    { name: 'Order Sync Lambda', scope: 'store/order/created', controller: '' },
]

const Index = () => {
    const { error, isLoading, list, mutateList } = useWebhooks();
    
    return (
        <Panel>
            {
                Webhooks.map(({ name, scope, controller }) => (
                    <Box border='box' borderRadius='normal' marginRight='xLarge' marginBottom='large' padding='medium'>
                        <WebhookForm
                            webhookController={controller}
                            webhookName={name}
                            webhookScope={scope}
                            error={error}
                            isLoading={isLoading}
                            list={list}
                            mutateList={mutateList}
                        />
                    </Box>
                ))
            }
        </Panel>
    );
};

const routes = {
    path: 'webhooks',
    Index: true,
    Component: Index
};

export default routes;
